import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

//COMPONENTS
import { Footer } from '../../components';
import Button from '../../components/Button';

import Logo from '../../assets/logo-2.png';
//STYLE
import {
  MainContainer,
  GlobalStyle,
  TermsText,
  ButtonWrapper,
  ButtonContainer,
  LogoWrapper,
} from './styles';

export default function TermsAndConditions(props) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <MainContainer>
      <GlobalStyle />
      <TermsText>
        <LogoWrapper src={Logo} /> 
        <b>TERMOS E CONDIÇÕES DE USO</b>
        <br />
        <br />
        O presente instrumento (“Termos e Condições de Uso”), tem por objeto
        regular os termos e as condições de uso dos serviços oferecidos pelo
        Synas, aplicativo de propriedade da <b>SYNAS SERVIÇOS DE INFORMAÇÃO NA
        INTERNET LTDA.</b>, sociedade limitada inscrita no CNPJ/MF sob o nº
        31.495.143/0001-45 e com sede na Rua Rodrigo Silva, nº 18, 12º andar,
        Centro, na Cidade e Estado do Rio de Janeiro, detentora de seus direitos
        autorais.
        <br />
        <br />
        A aceitação destes Termos e Condições de Uso e de todas as demais
        políticas e princípios que o regem é absolutamente indispensável a
        qualquer pessoa física ou jurídica que pretenda se tornar usuário do
        Synas.
        <br />
        <br />
        POR FAVOR, LEIA ESTE DOCUMENTO COM ATENÇÃO, POIS O USO DOS SERVIÇOS DO
APLICATIVO SYNAS POR VOCÊ DEMONSTRA QUE VOCÊ COM TODOS OS SEUS TERMOS. AO
CLICAR EM “LI E ACEITO”, VOCÊ CONCORDA COM ESTES TERMOS E CONDIÇÕES DE USO
DO APLICATIVO SYNAS. CASO VOCÊ TENHA QUALQUER DÚVIDA SOBRE ESSES
INSTRUMENTOS, RECOMENDAMOS QUE ENTRE EM CONTATO COM A SYNAS PELA SEÇÃO DE
CONTATO DE SEU APLICATIVO.
        <br />
        <br />
        A SYNAS PODERÁ MODIFICAR ESTE DOCUMENTO, A QUALQUER MOMENTO, PARA REFLETIR
EVENTUAIS ALTERAÇÕES NECESSÁRIAS. EVENTUAIS VERSÕES ATUALIZADAS SERÃO
PUBLICADAS NO SITE SYNAS.COM.BR, QUE DEVERÁ SER CONSULTADO REGULARMENTE.
        <br />
        <br />
        Para a sua comodidade, a data da última atualização é incluída no topo
        desta página e, posteriormente, enviaremos avisos sobre eventuais
        atualizações.
        <br />
        <br />
        Em caso de conflito entre este documento e eventual versão posterior, esta última prevalecerá
sobre a primeira.
        <br />
        <br />
        <b>1. DEFINIÇÕES</b>
        <br />
        <br />
        Toda vez que houver menção aos termos “Sociedade”, “nós” ou “nossos” estaremos nos
referindo à Synas Serviços de Informação na Internet Ltda.; e toda vez que houver menção aos
termos “Usuário”, “você”, “seu” ou “sua”, estaremos nos referindo à pessoa física ou jurídica
que pretenda utilizar o aplicativo Synas.
        <br />
        <br />
        Ademais, para facilitar seu entendimento e evitar repetições, os termos constantes neste
documento, sempre que usados com a primeira letra em maiúsculo, identificarão um termo ou
expressão definida, e serão aplicáveis para as eventuais atualizações que este documento
possa vir a sofrer, exceto se expressamente indicado o contrário.
        <br />
        <br />
        <b>2. O SYNAS</b>
        <br />
        <br />
        O Synas é um aplicativo de relacionamento desenvolvido pela Sociedade, disponível para
utilização nos sistemas IOS e Android. Nossa tecnologia se utiliza de parâmetros astrológicos
para indicar uma possível compatibilidade entre seus Usuários.
        <br />
        <br />
        <b>3. UTILIZAÇÃO DO SOFTWARE</b>
        <br />
        <br />
        Para utilizar o Synas, o Usuário precisa:
        <br />
        <br />
        a) ter, pelo menos, 18 anos de idade;
        <br />
        b) estar apto, juridicamente, a aceitar seus Termos,
        <br />
        c) não ter impedimentos legais, nos termos da jurisdição aplicável;
        <br />
        d) cumprir este contrato e toda a legislação pertinente, bem como a totalidade das normas e
regulamentos aplicáveis;
        <br />
        e) nunca ter sido condenado por crime grave, especialmente por assédio ou por qualquer
outro crime que envolva violência.
        <br />
        <br />
        Se cumprir todos os requisitos acima, você poderá realizar seu cadastro. Para isso, você deverá
enviar uma foto de perfil e informar seus seguintes dados: (i) nome completo; (ii) idade; (iii)
endereço de e-mail pessoal válido (login) que seja acessado regularmente; (iv) cidade em que
se encontra; (v) seus interesses; e (vi) data, local e horário de nascimento.
        <br />
        <br />
        Em seguida, você deverá criar uma senha pessoal e intransferível. Nesse sentido, você declara
ter conhecimento de que é o único responsável por manter a confidencialidade e o sigilo
absoluto de suas credenciais de login ao Synas, além de ser o único responsável por todas as
atividades associadas ao uso destas credenciais. Se você acredita que alguém obteve acesso à
sua conta, entre em contato conosco imediatamente.
        <br />
        <br />
        Para completar seu cadastro no aplicativo, o Usuário poderá preencher dados adicionais, como
o local onde trabalha e seu nível de escolaridade.
        <br />
        <br />
        Após finalizar seu cadastro, será automaticamente incluído no plano de acesso gratuito,
podendo futuramente escolher 1 (um) dos planos de acesso disponíveis no Synas: (i) Gratuito;
(ii) Standard; e (iii) Gold, devidamente detalhados no Anexo I (“Planos de Acesso”).
        <br />
        <br />
        Ao utilizar o Synas, você concorda que:
        <br />
        <br />
        * Os cadastros realizados com endereços de e-mail inválidos poderão ser bloqueados ou
excluídos, sem aviso prévio;
        <br />
        * SUA FOTO, SEU SIGNO E ASCENDENTE SERÃO DISPONIBILIZADOS AOS USUÁRIOS
IDENTIFICADOS PELO SYNAS COMO COMPATÍVEIS, E VICE-VERSA, PARA QUE POSSAM DECIDIR
SE DESEJAM OU NÃO INICIAR UM RELACIONAMENTO;
        <br />
        * O SYNAS UTILIZARÁ AS INFORMAÇÕES FORNECIDAS PELO MAPA ASTRAL DE SEUS USUÁRIOS,
ELABORADO A PARTIR DOS DADOS DE SEU CADASTRO PARA INDICAR POSSÍVEIS
COMPATIBILIDADES;
        <br />
        * A DISPONIBILIZAÇÃO DO MAPA ASTRAL DO USUÁRIO NÃO FAZ PARTE DOS SERVIÇOS
OFERECIDOS NO APLICATIVO.
        <br />
        * A realização de seu cadastro corresponde à aceitação do disposto neste documento e de
todas as demais políticas e princípios que o regem, podendo esta informação ser utilizada
como prova de sua aceitação, independentemente de outra formalidade.
        <br />
        * A pessoa física que deseja utilizar os serviços disponibilizados pelo Synas deverá ser
civilmente capaz, nos moldes da legislação civil vigente no Brasil, para que possa figurar como
Usuário;
        <br />
        * O Usuário poderá, a qualquer tempo, solicitar a exclusão de seu cadastro no Synas. Para
tanto, deverá notificar a Sociedade pela seção de contato disponibilizada no aplicativo e
aguardar o recebimento da mensagem de confirmação da exclusão. Para obter maiores
detalhes a este respeito, favor acessar nossa Política de Privacidade;
        <br />
        * Embora o serviço oferecido pelo Synas possa ser acessado de forma gratuita, para que o
Usuário tenha acesso a algumas funcionalidades especiais (“Serviços Premium”), deverá
realizar a aquisição de um plano de acesso e realizar o respectivo pagamento, conforme
disposto na Seção 4 deste documento. A liberação do acesso ao conteúdo dos Serviços
Premium está condicionada à confirmação de pagamento.
        <br />
        <br />
        <b>4. AQUISIÇÃO E CANCELAMENTO</b>
        <br />
        <br />
        Os planos Standard e Gold permitem o acesso do Usuário aos Serviços Premium e constituem
modalidades pagas de acesso. O Usuário poderá realizar o pagamento do Plano de Acesso
escolhido por meio do fornecimento de dados de cartão crédito, sendo certo que a respectiva
confirmação de pagamento poderá demorar até 3 (três) dias para ser reconhecida pela
Sociedade, por motivos de compensação bancária.
        <br />
        <br />
        A Sociedade declara que não deixa memorizados em seu aplicativo os dados de cartão de
crédito fornecidos pelo cliente e garante que os mesmos serão criptografados pelo sistema de
pagamento utilizado pelo Synas, para que sejam usados unicamente no processamento de
cobranças recorrentes.
        <br />
        <br />
        A falta de pagamento nas datas determinadas, ou do pagamento principal na modalidade à
vista, acarretará a suspensão de acesso da conta do Usuário aos Serviços Premium do Synas,
até que ocorra a regularização de sua pendência financeira.
        <br />
        <br />
        Os valores correspondentes aos planos de acesso poderão ser alterados a qualquer tempo pela
Sociedade, podendo o Usuário optar por não renovar seu acesso, caso discorde dos novos
valores propostos. Neste caso, seu acesso aos Serviços Premium será suspenso após
encerramento do último período contratado.
        <br />
        <br />
        Caso o Usuário venha a desistir da aquisição de qualquer Plano de Acesso do Synas, no período
de até 7 (sete) dias corridos após a data de confirmação da compra, terá direito à devolução de 100% (cem por cento) do valor pago. Após este período, não será devido o reembolso de
qualquer valor pela Sociedade e o Usuário permanecerá com a obrigação de pagamento, até
que haja o recebimento integral do valor correspondente a seu Plano de Acesso pela
Sociedade.
        <br />
        <br />
        <b>5. SOBRE OS DADOS FORNECIDOS POR VOCÊ AO SYNAS</b>
        <br />
        <br />
        Ao criar uma conta, você concede ao Synas uma licença universal, transferível, sublicenciável e
isenta de royalties, além do direito de armazenar, hospedar, reproduzir, exibir, utilizar, copiar,
adaptar, editar, publicar, modificar e distribuir informações que você nos autorizar a acessar
de redes sociais terceiras, como, por exemplo, o Facebook ou Instagram, bem como de outras
informações que você publicar, fizer upload, exibir ou, de outra forma, publicar no Aplicativo
ou transmitir a outros membros.
        <br />
        <br />
        Você concorda em disponibilizar no Synas apenas informações corretas, verdadeiras e que não
violem direitos de terceiros.
        <br />
        <br />
        Você concorda e está ciente de que a Sociedade possui o direito de excluir ou editar qualquer
conteúdo que viole ou possa a vir violar direitos de terceiros.
        <br />
        <br />
        A Sociedade não se responsabiliza, em hipótese alguma, pela autenticidade ou veracidade dos
dados disponibilizados pelos usuários em seu Aplicativo.
        <br />
        <br />
        <b>6. CONFIDENCIALIDADE</b>
        <br />
        <br />
        A Sociedade se compromete a manter a confidencialidade, integridade e segurança de
quaisquer Informações disponibilizadas pelo Usuário ou a ele relacionadas, utilizando-as
conforme descrito na Política de Privacidade e de acordo com o estabelecido pela legislação
aplicável.
        <br />
        <br />
        Com a finalidade de garantir a privacidade do Usuário, bem como a segurança de suas
Informações, a Sociedade se compromete a regularmente reavaliar a sua política de segurança
e adaptá-la, conforme necessário.
        <br />
        <br />
        <b>7. SEGURANÇA DOS USUÁRIOS EM RELAÇÃO AO CONTATO COM OUTROS MEMBROS</b>
        <br />
        <br />
        Para você manter contato com outro Usuário por meio do Aplicativo, você precisará aceitar
previamente que ele possa interagir com você e ele também precisará aceitar previamente a
interação com você.
        <br />
        <br />
        Nós não investigamos antecedentes criminais dos usuários e não nos responsabilizamos pelas
condutas que eles venham a adotar. Assim, você possui responsabilidade exclusiva por suas interações com outros membros e está ciente de que o Aplicativo não se responsabiliza por
quaisquer condutas, seja dentro ou fora do Aplicativo.
        <br />
        <br />
        Você reconhece que a Synas também não se responsabiliza por qualquer resultado relacional
decorrente das interações entre os usuários. A função do aplicativo é apenas indicar uma
possível compatibilidade astrológica e você deve ter ciência que o desfecho de qualquer
interação sofre a influência de inúmeros outros fatores sobre os quais, o Synas não tem
qualquer controle.
        <br />
        Além disso, você não deve fornecer informações financeiras (como dados de pix, cartões
bancários e informações sobre contas) e não deve transferir ou enviar dinheiro a outros
Usuários.
        <br />
        <br />
        <b>8. PROPRIEDADE INTELECTUAL</b>
        <br />
        <br />
        O uso comercial da expressão “Synas” como marca, nome empresarial ou nome de domínio,
bem como todo conteúdo disponível no aplicativo, incluindo, mas não se limitando a marcas,
logotipos, textos, gráficos, imagens, logos, telas, conteúdo editorial, notificações, software,
bancos de dados e demais instrumentos que permitem que o Usuário acesse e use sua conta,
são propriedades da Sociedade e estão protegidos pelas leis e tratados internacionais de
direito autoral, marcas, patentes, modelos e desenhos industriais. A cópia, reprodução,
distribuição, transmissão, publicação, conexão ou qualquer outra forma de uso indevido dos
conteúdos mencionados acima é proibida, salvo se com autorização expressa da Sociedade.
        <br />
        <br />
        O Usuário declara e reconhece que a reprodução de qualquer conteúdo disponível pelo
aplicativo do Synas não lhe confere sua titularidade, assim como a adesão ao presente
instrumento lhe atribui nenhum direito de propriedade intelectual ou outros direitos
exclusivos da Sociedade, incluindo patentes, desenhos, marcas, código fonte do software,
direitos sobre informações confidenciais ou segredos de negócio.
        <br />
        <br />
        Quaisquer marcas exibidas no aplicativo não devem ser consideradas como de domínio público
e são de titularidade exclusiva da Sociedade, de seus parceiros ou de terceiros licenciantes,
conforme o caso.
        <br />
        <br />
        A reprodução do conteúdo disponibilizado pelo Synas sem a devida autorização, ou ainda sem
a correspondente citação e referência ao Synas constituirá infração aos direitos de
propriedade intelectual da Sociedade, e sujeitará o Usuário às sanções administrativas, civis e
criminais cabíveis.
        <br />
        <br />
        <b>9. PRIVACIDADE DA INFORMAÇÃO</b>
        <br />
        <br />
        Toda informação ou dado pessoal prestados pelo Usuário à Sociedade para utilização do Synas
é armazenada em banco de dados próprio, através de servidores ou meios magnéticos de alta
segurança.
        <br />
        <br />
        A Sociedade tomará todas as medidas possíveis para manter a confidencialidade e a segurança
necessárias, porém não responderá por prejuízo que possa ser derivado da violação dessas
medidas por parte de terceiros que utilizem as redes públicas ou a internet, subvertendo os
sistemas de segurança para acessar as informações de seus Usuários.
        <br />
        <br />
        A Sociedade, ao se utilizar de qualquer das informações destes Termos e Condições de Uso,
não identificará a titularidade dos dados, mantendo o anonimato do Usuário.
        <br />
        <br />
        Em caso de dúvidas sobre a proteção a dados pessoais ou para obter maiores informações
sobre dados pessoais e os casos nos quais poderá ser quebrado o sigilo de que trata esta
Seção, o Usuário deverá consultar a Política de Privacidade e Proteção de Dados do Synas.
        <br />
        <br />
        <b>10. DEVERES E LIMITAÇÕES DE USO</b>
        <br />
        <br />
        Ao se cadastrar no Synas, o Usuário declara e reconhece que:
        <br />
        <br />
        * Aceita receber informações, materiais e mensagens relacionadas ao Synas;
        <br />
        * Deverá realizar o pagamento do valor referente ao Plano de Acesso escolhido até as
respectivas datas de vencimento, sob pena de suspensão de seu acesso a Serviços Premium
cobrança do valor acrescido de multa e juros de mora;
        <br />
        * Deverá cumprir com os termos e condições ora estabelecidos; e
        <br />
        * Não está autorizado a republicar o conteúdo do aplicativo em nenhum outro meio, incluindo
outros sites.
        <br />
        <br />
        Uma vez constatado o uso fraudulento do Synas, a Sociedade promoverá a exclusão do
Usuário do aplicativo, nada lhe sendo devido a que título for.
        <br />
        <br />
        O Usuário declara, ainda, reconhecer que não poderá:
        <br />
        <br />
        * Incluir informações falsas ou de propriedade de terceiros em seu cadastro;
        <br />
        * Utilizar o Synas para divulgar informações de qualquer forma que possa implicar em violação
de normas aplicáveis no Brasil, de direitos de propriedade da Synas e/ou de terceiros ou dos
bons costumes, incluindo, sem limitação, a violação de direitos intelectuais, autorais e de
privacidade, ou a produção e divulgação de conteúdo ilegal, imoral, inapropriado ou ofensivo;
        <br />
        * Copiar, ceder, sublicenciar, vender, dar em locação ou em garantia, reproduzir, doar, alienar
de qualquer forma, transferir total ou parcialmente, sob quaisquer modalidades, gratuita ou
onerosamente, provisória ou permanentemente, o acesso ao Synas, assim como seus
módulos, partes, manuais ou quaisquer informações relativas ao mesmo;
        <br />
        * Empregar softwares, técnicas e/ou artifícios com o intuito de utilizar indevidamente o Synas
para práticas nocivas à Sociedade ou a terceiros, tais como a prática de
fraudes, spamming, flooding e spoofing, etc;
        <br />
        * Reproduzir, adaptar, modificar e/ou empregar, no todo ou em parte, para qualquer fim, o
aplicativo sem a autorização expressa da Sociedade;
        <br />
        * Publicar ou transmitir qualquer arquivo que contenha vírus, malwares, spywares ou qualquer
outro programa contaminante ou destrutivo, ou que de outra forma possa interferir no bom
funcionamento do Synas; ou
        <br />
        * Utilizar o aplicativo para finalidade diversa daquela para a qual foi disponibilizado pela
Sociedade.
        <br />
        <br />
        <b>11. RESPONSABILIDADE DAS PARTES</b>
        <br />
        <br />
        Adicionalmente às declarações prestadas e obrigações assumidas nestes Termos e Condições
de Uso, o Usuário declara que está ciente de que o Synas, assim como qualquer outra
plataforma de tecnologia, está suscetível a falhas, erros, problemas de acesso e similares, que
fogem do controle da Sociedade.
        <br />
        <br />
        O Usuário assumirá toda a responsabilidade civil e criminal perante a Sociedade pelo
descumprimento das obrigações previstas neste documento, pela inexatidão das suas
declarações e por qualquer conduta ilícita, devendo indenizá-la, imediatamente, por quaisquer
prejuízos e despesas, incluindo honorários de advogado e custas judiciais, incorridas pela
Sociedade.
        <br />
        <br />
        O Synas é uma plataforma que proporciona aos seus usuários a liberdade de expressar sua
personalidade por meio de seu perfil, dessa maneira, para além da compatibilidade astral, é
possível que se criem vínculos pautados por outras características. Portanto, não é realizado
controle quanto a descrições de BIO ou imagens postadas nos perfis, sendo isenta a Sociedade
de qualquer dano decorrente dos dados e informações prestados por terceiros ou por outros
usuários.
        <br />
        <br />
        A Sociedade não responderá, em nenhuma hipótese, ainda que em caráter solidário ou
subsidiário:
        <br />
        <br />
        * Pela autenticidade, validade e precisão dos dados fornecidos pelo Usuário;
        <br />
        * Por eventuais prejuízos sofridos pelo Usuário em razão da tomada de decisões com base nas
informações disponibilizadas no Synas;
        <br />
        * Por situações de caso fortuito ou força maior, nos termos do artigo 393 do Código Civil
Brasileiro; e
        <br />
        * Eventuais prejuízos sofridos pelo Usuário em razão de falhas no sistema de informática ou
nos servidores que independam de culpa da Sociedade ou em sua conectividade com
a internet de modo geral, devendo o Usuário manter, às suas expensas, linha de
telecomunicação, modem, software de comunicação, endereço de correio eletrônico e outros
recursos necessários para a comunicação com a Sociedade.
        <br />
        <br />
        A Sociedade não garante que as funções contidas no Synas atendam às suas necessidades, que
sua operação será ininterrupta ou livre de erros ou que o aplicativo será compatível ou
funcione com qualquer outro aplicativo, software ou serviços de terceiros.
        <br />
        <br />
        Em nenhum caso, a Sociedade será responsabilizada por danos pessoais ou qualquer prejuízo
incidental, especial, indireto ou consequente, lucros cessantes, incluindo, sem limitação,
prejuízos por perda de lucro, corrupção ou perda de dados, falha de transmissão ou recepção
de dados, não continuidade do negócio ou qualquer outro prejuízo ou perda comercial,
decorrentes ou relacionados ao uso de seu aplicativo, por qualquer outro motivo.
        <br />
        <br />
        O Usuário entende e concorda que a Sociedade irá adotar medidas de segurança e de proteção
compatíveis com a natureza dos dados coletados, usados e armazenados pela Sociedade, no
entanto, a Sociedade não garante de forma alguma que tais medidas de segurança sejam
isentas de erros ou que não estejam sujeitas a interferência de terceiros (hackers, entre
outros). Por sua natureza, apesar dos melhores esforços da Sociedade, qualquer medida de
segurança pode falhar e qualquer dado do Usuário fornecido ou coletado pela Sociedade pode
se tornar público. AO ACEITAR O TERMO DE USO E/OU USAR O SYNAS, O USUÁRIO ENTENDE E
ASSUME EXPRESSAMENTE ESSE RISCO E CONCORDA QUE A SOCIEDADE NÃO SERÁ
RESPONSÁVEL POR TAL TIPO DE VAZAMENTO DE DADOS, DESDE QUE COMPROVADO QUE
TODAS AS MEDIDAS DE SEGURANÇA FORAM ADOTADAS.
        <br />
        <br />
        Em um eventual caso de vazamento de dados, a Sociedade informará imediatamente o
Usuário para que sejam tomadas as medidas necessárias de contenção de danos.
        <br />
        <br />
        <b>12. VIGÊNCIA E RESCISÃO</b>
        <br />
        <br />
        Estes Termos e Condições terão prazo indeterminado, tendo início a partir do aceite do
Usuário.
        <br />
        <br />
        O Usuário poderá, a qualquer momento, solicitar a exclusão da sua conta de acesso ao Synas,
encerrando seu relacionamento com a Sociedade, mediante solicitação na aba de contato
disponibilizada em seu aplicativo.
        <br />
        <br />
        <b>13. DISPOSIÇÕES FINAIS</b>
        <br />
        <br />
        Na hipótese de qualquer cláusula, termo ou disposição destes Termos e Condições de Uso vir a
ser declarada nula ou inexequível, tal nulidade ou inexequibilidade não afetará quaisquer
outras cláusulas, termos ou condições aqui contidos, os quais permanecerão em pleno vigor e
efeito.
        <br />
        <br />
        Estes Termos e Condições de Uso constituem a totalidade do acordo sobre as condições de uso
do Synas. O Usuário declara ter ciência dos direitos e obrigações decorrentes deste
documento, tendo lido, compreendido e aceito todos os termos e condições aqui dispostos.
        <br />
        <br />
        A tolerância de uma parte em relação à outra quanto ao descumprimento de qualquer uma
das obrigações assumidas neste instrumento não implicará em novação ou renúncia de direito.
A parte tolerante poderá, a qualquer tempo, exigir da outra parte o fiel e cabal cumprimento
do conteúdo previsto. O Usuário concorda que a Sociedade possa enviar notificações via correio eletrônico sobre parcerias e campanhas relacionadas aos serviços disponibilizados pelo
Synas.
        <br />
        <br />
        <b>14. LEI E FORO APLICÁVEIS</b>
        <br />
        <br />
        Estes Termos e Condições de Uso serão interpretados exclusivamente segundo as leis do
Brasil.
        <br />
        <br />
        As partes elegem o Foro da Comarca da Capital do Rio de Janeiro como o único competente
para dirimir qualquer litígio resultante deste instrumento.
        <br />
        <br />
      </TermsText>

      <Link to="/">
        <ButtonWrapper>
          <ButtonContainer>
            <Button>Entendi</Button>
          </ButtonContainer>
        </ButtonWrapper>
      </Link>

      <Footer />
    </MainContainer>
  );
}
