import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { createBrowserHistory } from 'history';

//THEME
import { themes } from './theme';

//CONTAINERS
import Page from './pages';
import TermsAndConditions from './pages/TermsAndConditions';
import Eula from './pages/Eula';

import './App.css';
import Privacy from './pages/Privacy';
import LandingPage from './pages/Landing/LandingPage';
import CompatibilityPage from './pages/Landing/CompatibilityPage';
import ResetPassword from './pages/ResetPassword';

const history = createBrowserHistory();

function App() {
  return (
    <ThemeProvider theme={themes}>
      <BrowserRouter basename={'/'} history={history}>
        <Switch>
          <Route exact path="/eula" component={Eula} />
          <Route exact path="/terms" component={TermsAndConditions} />
          <Route exact path="/privacy" component={Privacy} />
          <Route path={"/landing"} component={LandingPage} exact />
          <Route path={"/reset-password"} component={ResetPassword} exact />
          <Route path={"/compatibility"} component={CompatibilityPage} exact />
          <Route exact path="/" component={Page} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
