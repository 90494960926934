import animatedNumber from './AnimatedNumber';
import animation from './Animation';
import bodySection from './BodySection';
import button from './Button';
import dropdownList from './DropdownList';
import footer from './Footer';
import header from './Header';
import modalLoading from './Modal/ModalLoading';
import modalPairUpdate from './Modal/ModalPairUpdate';
import modalProfile from './Modal/ModalProfile';
import modalSignup from './Modal/ModalSignup';
import modalTerms from './Modal/ModalTerms';
import profilePhoto from './ProfilePhoto';
import progressBar from './ProgressBar';
import sign from './Sign';
import topSection from './TopSection';

export const AnimatedNumber = animatedNumber;
export const Animation = animation;
export const BodySection = bodySection;
export const Button = button;
export const DropdownList = dropdownList;
export const Footer = footer;
export const Header = header;
export const ModalLoading = modalLoading;
export const ModalPairUpdate = modalPairUpdate;
export const ModalProfile = modalProfile;
export const ModalSignup = modalSignup;
export const ModalTerms = modalTerms;
export const ProfilePhoto = profilePhoto;
export const ProgressBar = progressBar;
export const Sign = sign;
export const TopSection = topSection;
