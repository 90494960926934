import ColoredAries from '../../assets/Aries-color.svg';
import ColoredTaurus from '../../assets/Touro-color.svg';
import ColoredGemini from '../../assets/Gemeos-color.svg';
import ColoredCancer from '../../assets/Cancer-color.svg';
import ColoredLeo from '../../assets/Leao-color.svg';
import ColoredVirgo from '../../assets/Virgem-color.svg';
import ColoredLibra from '../../assets/Libra-color.svg';
import ColoredScorpio from '../../assets/Escorpiao-color.svg';
import ColoredSagittarius from '../../assets/Sagitario-color.svg';
import ColoredCapricorn from '../../assets/Capricornio-color.svg';
import ColoredAquarius from '../../assets/Aquario-color.svg';
import ColoredPisces from '../../assets/Peixes-color.svg';

import Sun from '../../assets/sun.svg'
import Asc from '../../assets/asc-color.svg'
import Moon from '../../assets/moon.svg'
import Saturn from '../../assets/saturn.svg'
import Neptune from '../../assets/neptune.svg'
import Venus from '../../assets/venus.svg'
import Mercury from '../../assets/mercury.svg'
import Mars from '../../assets/mars.svg'
import Jupiter from '../../assets/jupiter.svg'
import Pluto from '../../assets/pluto.svg'
import Uranus from '../../assets/uranus.svg'
import Chiron from '../../assets/chiron.svg'
import Taurus from '../../assets/touro-planet.svg'

export const getSignIcon = (sign) => {
  switch (sign) {
    case 'Aquário':
      return ColoredAquarius
    case 'Áries':
      return ColoredAries
    case 'Leão':
      return ColoredLeo
    case 'Câncer':
      return ColoredCancer
    case 'Capricórnio':
      return ColoredCapricorn
    case 'Gêmeos':
      return ColoredGemini
    case 'Libra':
      return ColoredLibra
    case 'Peixes':
      return ColoredPisces
    case 'Sagitário':
      return ColoredSagittarius
    case 'Escorpião':
      return ColoredScorpio
    case 'Touro':
      return ColoredTaurus
    case 'Virgem':
      return ColoredVirgo
    default:
      return ColoredAries
  }
}

export const getPlanetIcon = (planet) => {
  switch(planet) {
    case 'Sol':
      return Sun
    case 'Ascendente':
      return Asc
    case 'Lua':
      return Moon
    case 'Saturno':
      return Saturn
    case 'Netuno':
      return Neptune
    case 'Vênus':
      return Venus
    case 'Mercúrio':
      return Mercury
    case 'Marte':
      return Mars
    case 'Júpiter':
      return Jupiter
    case 'Plutão':
      return Pluto
    case 'Urano':
      return Uranus
    case 'Quíron':
      return Chiron
    case 'MC':
      return Taurus
    default:
      return Sun
  }
}