import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SynasLogo from '../../assets/logo.png';
import SynasLogoColor from '../../assets/logo-color.png'

import './styles.css'

const invalidText = () => {
  return <h3>
    Token inválido, solicite novamente a troca de senha em nosso app
  </h3>
}

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [token, setToken] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    checkToken()
  }, [window.location.search])

  const checkToken = async () => {
    try {
      setIsLoading(true)

      const token = new URLSearchParams(window.location.search).get('token')
      const email = new URLSearchParams(window.location.search).get('email')
      const response = await axios.get(`https://apis.synas.com.br/profile/v1/auth/check-token?token=${token}&email=${email}`)

      setToken(token)
      setEmail(email)
      setIsValid(response.data.isValid)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const changePassword = async () => {
    try {
      setIsLoading(true)

      if(password !== confirmPassword) {
        setErrorMessage("As senhas não conferem")
      } else if (password.length < 6) {
        setErrorMessage("Senha muito fraca")
      } else {
        const response = await axios.post(`https://apis.synas.com.br/profile/v1/auth/redefine-password`, {
          token,
          email,
          password,
          confirm_password: confirmPassword
        })

        alert("Senha alterada com sucesso! ⭐")
      }
    } catch (e) {
      alert(`Parece que os astros se desalinharam 🪐\n${e.message}`)
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading)
    return <div className={"loading-container"}>
      <img className={"synas-logo"} src={SynasLogo} alt={"Synas Logo"} />
      <div className="lds-heart">
        <div></div>
      </div>
    </div>

  return <>
    <div className={"reset-wrapper"}>
      <div className={"reset-password"}>
        {
          !isValid ? invalidText() :
            <>
              <img src={SynasLogoColor} alt={'Synas Logo'} />
              <div className={"inputs"}>
                <div className={"input-container"}>
                  <label htmlFor={"token"}>Token</label>
                  <input id={"token"} value={token} readOnly={true} />
                </div>
                <div className={"input-container"}>
                  <label htmlFor={"email"}>Email</label>
                  <input id={"email"} type={"email"} value={email} readOnly={true} />
                </div>
                <div className={"input-container"}>
                  <label htmlFor={"password"}>Senha</label>
                  <input type={"password"} id={"password"} value={password}
                         onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className={"input-container"}>
                  <label htmlFor={"confirmPassword"}>Confirme sua senha</label>
                  <input type={"password"} id={"confirmPassword"} value={confirmPassword}
                         onChange={(e) => setConfirmPassword(e.target.value)} />
                </div>
                <span>{errorMessage}</span>
                <button onClick={changePassword} type={"button"}>Confirmar</button>
              </div>
            </>
        }
      </div>
    </div>
  </>
}

export default ResetPassword;