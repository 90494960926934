import {useParams} from "react-router-dom";
import React, { useEffect, useState } from 'react';

import './styles.css'
import "react-datepicker/dist/react-datepicker.css";
import { Header, ProgressBar } from '../../components';
import DatePicker from 'react-datepicker';
import MaleImage from '../../assets/male.png'
import FemaleImage from '../../assets/female.png'
import { getPlanetIcon, getSignIcon } from './utils';
import { ColoredLine } from '../BodyCompatibility/CompatibilitySection/styles';
import axios from 'axios';
import * as luxon from 'luxon';
import SynasLogo from '../../assets/logo.png'
import SynasLogoColor from '../../assets/logo-color.png'

const UF_LIST = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MS',
  'MT',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
]

const CompatibilityPage = () => {
  const [namePersonOne, setNamePersonOne] = useState("")
  const [sexPersonOne, setSexPersonOne] = useState("")
  const [birth_placePersonOne, setBirth_placePersonOne] = useState("")
  const [ufPersonOne, setUfPersonOne] = useState("")
  const [birth_datePersonOne, setBirth_datePersonOne] = useState()
  const [emailPersonOne, setEmailPersonOne] = useState("")
  const [noTimePersonOne, setNoTimePersonOne] = useState(false)
  const [mapPersonOne, setMapPersonOne] = useState()
  const [luxonBirthDatePersonOne, setLuxonBirthDatePersonOne] = useState(luxon.DateTime.now())

  const [namePersonTwo, setNamePersonTwo] = useState("")
  const [sexPersonTwo, setSexPersonTwo] = useState("")
  const [birth_placePersonTwo, setBirth_placePersonTwo] = useState("")
  const [ufPersonTwo, setUfPersonTwo] = useState("")
  const [birth_datePersonTwo, setBirth_datePersonTwo] = useState()
  const [emailPersonTwo, setEmailPersonTwo] = useState("")
  const [noTimePersonTwo, setNoTimePersonTwo] = useState(false)
  const [mapPersonTwo, setMapPersonTwo] = useState()
  const [luxonBirthDatePersonTwo, setLuxonBirthDatePersonTwo] = useState(luxon.DateTime.now())

  const [compatibility, setCompatibility] = useState()
  const [origin, setOrigin] = useState('synas')
  const [isLoading, setIsLoading] = useState(false)
  const [logoUrl, setLogoUrl] = useState()

  useEffect(() => {
    const path = new URLSearchParams(window.location.search).get('path')
    if(path)
      loadLandingDetails()
  }, [window.location.search])

  const loadLandingDetails = async () => {
    try {
      setIsLoading(true)

      const landingId = new URLSearchParams(window.location.search).get('path')
      const response = await axios.get(`https://apis.synas.com.br/profile/v1/landings/${landingId}`)
      const landingData = response.data.page

      window.document.title = landingData.title
      setLogoUrl(landingData.logo)
      setOrigin(landingData.key)
    } catch (e) {
      alert(`Parece que os astros se desalinharam 🪐\n${e.message}`)
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const generateMap = async () => {
    if(sexPersonOne === '' || sexPersonTwo === '')
      return alert('Selecioneo o sexo de ambos')
    else if(namePersonOne === '' || namePersonTwo === '')
      return alert('Insira o nome de ambos')
    else if(emailPersonOne === '' || emailPersonTwo === '')
      return alert('Insira um email válido para ambos')
    else if(birth_placePersonOne === '' || birth_placePersonTwo === '')
      return alert('Insira o local de nascimento de ambos')
    else if(ufPersonOne === '' || ufPersonTwo === '')
      return alert('Selecioneo estado de ambos')

    let luxonDateOne = luxon.DateTime.fromJSDate(new Date(birth_datePersonOne))
    let luxonDateTwo = luxon.DateTime.fromJSDate(new Date(birth_datePersonTwo))
    setIsLoading(true)
    if(noTimePersonOne) {
      luxonDateOne = luxonDateOne.set({ hour: 0, minute: 0, second: 0 })
      luxonDateTwo = luxonDateTwo.set({ hour: 0, minute: 0, second: 0 })
    }

    setLuxonBirthDatePersonOne(luxonDateOne)
    setLuxonBirthDatePersonTwo(luxonDateTwo)

    try {
      const compatibilityData = await axios.post('https://apis.synas.com.br/profile/v1/compatibility/test', {
        person_one: {
          sex: sexPersonOne,
          birth_place: birth_placePersonOne,
          uf: ufPersonOne,
          birth_date: luxonDateOne.toFormat("yyyy-MM-dd HH:mm:ss"),
          email: emailPersonOne,
          no_birth_time: noTimePersonOne,
          origin,
          name: namePersonOne
        },
        person_two: {
          sex: sexPersonTwo,
          birth_place: birth_placePersonTwo,
          uf: ufPersonTwo,
          birth_date: luxonDateTwo.toFormat("yyyy-MM-dd HH:mm:ss"),
          email: emailPersonTwo,
          no_birth_time: noTimePersonTwo,
          origin,
          name: namePersonTwo
        }
      })
      setMapPersonOne(compatibilityData.data.info.user_one)
      setMapPersonTwo(compatibilityData.data.info.user_two)
      setCompatibility({
        love: Number.parseFloat(compatibilityData.data.info.compatibility.ResultadoAmor.toString().replaceAll(".", ',')),
        sex: Number.parseFloat(compatibilityData.data.info.compatibility.ResultadoSexo.toString().replaceAll(".", ',')),
        affinity: Number.parseFloat(compatibilityData.data.info.compatibility.ResultadoAfinidade.toString().replaceAll(".", ',')),
        total: Number.parseFloat(compatibilityData.data.info.compatibility.ResultadoTotal.toString().replaceAll(".", ","))
      })
    } catch (e) {
      alert(`Parece que os astros se desalinharam 🪐\n${e.message}`)
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  return <>
    {isLoading && <div className={"loading-container"}>
      <img className={"synas-logo"} src={SynasLogo} alt={"Synas Logo"}/>
      <div className="lds-heart"><div></div></div>
    </div>}
    <div className="landing-top">
      <Header
        clickLogo={() => {}}
        clickEnter={() => {}}
        clickExit={() => {}}
        ccompatibilitylickTheApp={() => {window.document.location.href = "https://synas.com.br"}}
        clickCompatibilityTest={() => {}}
      />
    </div>

    <div className={"landing-wrapper"}>
      <div className={"landing-logo-container"}>
        <img src={logoUrl ?? SynasLogoColor} alt={"Logo"} />
      </div>
    <div className={"landing-container compatibility"}>
      <div className={"landing-left-column"}>
        <div style={{marginBottom: 0}} className={"landing-text-container"}>
          <h3>Descubra mais sobre compatibilidade</h3>
        </div>
        {compatibility && <div className={"landing-compatibility-container"}>
          <h3>Compatibilidade</h3>
          <ProgressBar title={"Afinidade"} color={"#81d4fa"} percentage={compatibility.affinity} />
          <ProgressBar title={"Amor"} color={"#A92B80"} percentage={compatibility.love} />
          <ProgressBar title={"Sexo"} color={"#fbc02d"} percentage={compatibility.sex} />
          <ProgressBar title={"Total"} color={"#4A5197"} percentage={compatibility.total} />
        </div>}
        <div className={"landing-basic-info-container"}>
          <form>
            <div className={"row"}>
              <h3 style={{textAlign: 'start'}}>Pessoa 1</h3>
            </div>
            <div className={"row mb"}>
              <div className={"col-12"}>
                <input type={"text"} placeholder={"Email"} value={emailPersonOne} onChange={(e) => setEmailPersonOne(e.target.value)}/>
              </div>
            </div>
            <div className={"row mb"}>
              <div className={"col-9"}>
                <input placeholder={"Nome"} type={"text"} value={namePersonOne} onChange={(e) => setNamePersonOne(e.target.value)}/>
              </div>
              <div className={"ml col-3"}>
                <select onChange={(e) => setSexPersonOne(e.target.value)}>
                  <option value="" disabled selected hidden>Sexo</option>
                  <option selected={sexPersonOne === "MALE"} value={"MALE"}>M</option>
                  <option selected={sexPersonOne === "FEMALE"} value={"FEMALE"}>F</option>
                </select>
              </div>
            </div>
            <div className={"row mb"}>
              <div className={"col-9"}>
                <input placeholder={"Cidade de Nascimento"} type={"text"} value={birth_placePersonOne} onChange={(e) => setBirth_placePersonOne(e.target.value)}/>
              </div>
              <div className={"ml col-3"}>
                <select onChange={(e) => setUfPersonOne(e.target.value)}>
                  <option value="" disabled selected hidden>UF</option>
                  {
                    UF_LIST.map((item) => <option selected={item === ufPersonOne} key={item} value={item}>{item}</option>)
                  }
                </select>
              </div>
            </div>
            <div className={"row mb"}>
              <div className={"col-12"}>
                <DatePicker dateFormat="dd/MM/yyyy hh:mm aa" placeholderText={"Data de Nascimento"} showTimeSelect selected={birth_datePersonOne} onChange={(date) => setBirth_datePersonOne(date)} />
              </div>
            </div>
            <div className={"row mb"}>
              <div className={"col-12"}>
                <label className={"checkbox"}><input checked={noTimePersonOne} onChange={(e) => setNoTimePersonOne(e.target.checked)} type={"checkbox"}/>Não sei a hora de nascimento</label>
              </div>
            </div>
            <div className={"row"}>
              <h3 style={{textAlign: 'start'}}>Pessoa 2</h3>
            </div>
            <div className={"row mb"}>
              <div className={"col-12"}>
                <input type={"text"} placeholder={"Email"} value={emailPersonTwo} onChange={(e) => setEmailPersonTwo(e.target.value)}/>
              </div>
            </div>
            <div className={"row mb"}>
              <div className={"col-9"}>
                <input placeholder={"Nome"} type={"text"} value={namePersonTwo} onChange={(e) => setNamePersonTwo(e.target.value)}/>
              </div>
              <div className={"ml col-3"}>
                <select onChange={(e) => setSexPersonTwo(e.target.value)}>
                  <option value="" disabled selected hidden>Sexo</option>
                  <option selected={sexPersonTwo === "MALE"} value={"MALE"}>M</option>
                  <option selected={sexPersonTwo === "FEMALE"} value={"FEMALE"}>F</option>
                </select>
              </div>
            </div>
            <div className={"row mb"}>
              <div className={"col-9"}>
                <input placeholder={"Cidade de Nascimento"} type={"text"} value={birth_placePersonTwo} onChange={(e) => setBirth_placePersonTwo(e.target.value)}/>
              </div>
              <div className={"ml col-3"}>
                <select onChange={(e) => setUfPersonTwo(e.target.value)}>
                  <option value="" disabled selected hidden>UF</option>
                  {
                    UF_LIST.map((item) => <option selected={item === ufPersonTwo} key={item} value={item}>{item}</option>)
                  }
                </select>
              </div>
            </div>
            <div className={"row mb"}>
              <div className={"col-12"}>
                <DatePicker dateFormat="dd/MM/yyyy hh:mm aa" placeholderText={"Data de Nascimento"} showTimeSelect selected={birth_datePersonTwo} onChange={(date) => setBirth_datePersonTwo(date)} />
              </div>
            </div>
            <div className={"row mb"}>
              <div className={"col-12"}>
                <label className={"checkbox"}><input checked={noTimePersonTwo} onChange={(e) => setNoTimePersonTwo(e.target.checked)} type={"checkbox"}/>Não sei a hora de nascimento</label>
              </div>
            </div>
            <div className={"row mt-2 mb"}>
              <div className={"col-12"}>
                <button type={"button"} onClick={generateMap}>Testar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={"landing-right-column compatibility-maps"}>
        {mapPersonOne && <div style={{marginTop: 36}} className={"landing-planets-info-container"}>
          <img src={sexPersonOne === "MALE" ? MaleImage : FemaleImage} alt={"User image"} />
          <div className={"colored-line-container"}>
            <ColoredLine />
          </div>
          <div className={"landing-user-info"}>
            <p className={"landing-user-name"}>
              {namePersonOne} - {luxonBirthDatePersonOne.toFormat("dd/MM/yyyy")}
            </p>
            <p className={"landing-user-born"}>
              Nascido(a) em {birth_placePersonOne}, {ufPersonOne} - Brasil às {luxonBirthDatePersonOne.toFormat("HH:mm")}
            </p>
          </div>
          <div className={"planets"}>
            {
              mapPersonOne.astral_map.map((item) => <div className={"grid-row planet-row"}>
                <div className={'row'}>
                  <img src={getPlanetIcon(item[0])} alt={'Sun'} />
                  {item[0]}
                </div>
                <div className={'row'}>
                  <img src={getSignIcon(item[1])} alt={'Aries'} />
                  {item[1]}
                </div>
                <div className={'row'}>
                  {item[2]}
                </div>
              </div>)
            }
          </div>
          <div className={"mandala-container"}>
            <img src={mapPersonOne.mandala} alt={'mandala'} />
          </div>
        </div>}
        {mapPersonTwo && <div style={{marginTop: 36}} className={"landing-planets-info-container"}>
          <img src={sexPersonTwo === "MALE" ? MaleImage : FemaleImage} alt={"User image"} />
          <div className={"colored-line-container"}>
            <ColoredLine />
          </div>
          <div className={"landing-user-info"}>
            <p className={"landing-user-name"}>
              {namePersonTwo} - {luxonBirthDatePersonTwo.toFormat("dd/MM/yyyy")}
            </p>
            <p className={"landing-user-born"}>
              Nascido(a) em {birth_placePersonTwo}, {ufPersonOne} - Brasil às {luxonBirthDatePersonTwo.toFormat("HH:mm")}
            </p>
          </div>
          <div className={"planets"}>
            {
              mapPersonTwo.astral_map.map((item) => <div className={"grid-row planet-row"}>
                <div className={'row'}>
                  <img src={getPlanetIcon(item[0])} alt={'Sun'} />
                  {item[0]}
                </div>
                <div className={'row'}>
                  <img src={getSignIcon(item[1])} alt={'Aries'} />
                  {item[1]}
                </div>
                <div className={'row'}>
                  {item[2]}
                </div>
              </div>)
            }
          </div>
          <div className={"mandala-container"}>
            <img src={mapPersonTwo.mandala} alt={'mandala'} />
          </div>
        </div>}
      </div>
    </div>
    </div>
  </>
}

export default CompatibilityPage