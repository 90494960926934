const SYNAS = 'synas@';
export const USER_TOKEN_STORAGE_NAME = SYNAS + 'U_T';
export const USER_STORAGE_NAME = SYNAS + 'U_D';
export const USER_PAIRS = SYNAS + 'U_P';
export const USER_PAIR = SYNAS + 'U_PS';
export const USER_COMPATIBILITY = SYNAS + 'U_C';
export const COMPATIBILITY_TOKEN = SYNAS + 'C_T';
export const API_URL = 'https://api-lp.synas.com.br/api';
export const API_MANDALA_URL = 'https://api.synas.com.br/api';
export const API_COMPATIBILITY_URL = 'https://algorithm.synas.com.br/api';
