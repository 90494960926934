import {useParams} from "react-router-dom";
import React, { useEffect, useState } from 'react';

import './styles.css'
import "react-datepicker/dist/react-datepicker.css";
import { Header } from '../../components';
import DatePicker from 'react-datepicker';
import MaleImage from '../../assets/male.png'
import FemaleImage from '../../assets/female.png'
import { getPlanetIcon, getSignIcon } from './utils';
import { ColoredLine } from '../BodyCompatibility/CompatibilitySection/styles';
import axios from 'axios';
import * as luxon from 'luxon';
import SynasLogo from '../../assets/logo.png'
import SynasLogoColor from '../../assets/logo-color.png'

const UF_LIST = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MS',
  'MT',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
]

const LandingPage = () => {
  const [name, setName] = useState("")
  const [sex, setSex] = useState("")
  const [birth_place, setBirthPlace] = useState("")
  const [uf, setUF] = useState("")
  const [birth_date, setBirthDate] = useState()
  const [email, setEmail] = useState("")
  const [noTime, setNoTime] = useState(false)
  const [map, setMap] = useState()
  const [luxonBirthDate, setLuxonBirthDate] = useState(luxon.DateTime.now())
  const [origin, setOrigin] = useState('synas')
  const [isLoading, setIsLoading] = useState(false)
  const [logoUrl, setLogoUrl] = useState()

  useEffect(() => {
    const path = new URLSearchParams(window.location.search).get('path')
    if(path)
      loadLandingDetails()
  }, [window.location.search])

  const loadLandingDetails = async () => {
    try {
      setIsLoading(true)

      const landingId = new URLSearchParams(window.location.search).get('path')
      const response = await axios.get(`https://apis.synas.com.br/profile/v1/landings/${landingId}`)
      const landingData = response.data.page

      window.document.title = landingData.title
      setLogoUrl(landingData.logo)
      setOrigin(landingData.key)
    } catch (e) {
      alert(`Parece que os astros se desalinharam 🪐\n${e.message}`)
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const generateMap = async () => {
    if(sex === '')
      return alert('Selecione um sexo')
    else if(name === '')
      return alert('Insira seu nome')
    else if(email === '')
      return alert('Insira um email válido')
    else if(birth_place === '')
      return alert('Insira seu local de nascimento')
    else if(uf === '')
      return alert('Selecione um estado')

    let luxonDate = luxon.DateTime.fromJSDate(new Date(birth_date))
    setIsLoading(true)
    if(noTime) {
      luxonDate = luxonDate.set({ hour: 0, minute: 0, second: 0 })
    }

    setLuxonBirthDate(luxonDate)

    try {
      const userData = await axios.post('https://apis.synas.com.br/profile/v1/mandala/generate', {
        sex,
        birth_place,
        uf,
        birth_date: luxonDate.toFormat("yyyy-MM-dd HH:mm:ss"),
        email,
        no_birth_time: noTime,
        origin,
        name
      })
      setMap(userData.data.info)
    } catch (e) {
      alert(`Parece que os astros se desalinharam 🪐\n${e.message}`)
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  return <>
    {isLoading && <div className={"loading-container"}>
      <img className={"synas-logo"} src={SynasLogo} alt={"Synas Logo"}/>
      <div className="lds-heart"><div></div></div>
    </div>}
    <div className="landing-top">
      <Header
        clickLogo={() => {}}
        clickEnter={() => {}}
        clickExit={() => {}}
        clickTheApp={() => {window.document.location.href = "https://synas.com.br"}}
        clickCompatibilityTest={() => {}}
      />
    </div>

    <div className={"landing-wrapper"}>
      <div className={"landing-logo-container"}>
        <img src={logoUrl ?? SynasLogoColor} alt={"Logo"} />
      </div>
    <div className={"landing-container"}>
      <div className={"landing-left-column"}>
        <div className={"landing-text-container"}>
          <h3>Descubra mais sobre o seu mapa</h3>
        </div>
        <div className={"landing-basic-info-container"}>
          <form>
            <div className={"row mb"}>
              <div className={"col-12"}>
                <input type={"text"} placeholder={"Email"} value={email} onChange={(e) => setEmail(e.target.value)}/>
              </div>
            </div>
            <div className={"row mb"}>
              <div className={"col-9"}>
                <input placeholder={"Nome"} type={"text"} value={name} onChange={(e) => setName(e.target.value)}/>
              </div>
              <div className={"ml col-3"}>
                <select onChange={(e) => setSex(e.target.value)}>
                  <option value="" disabled selected hidden>Sexo</option>
                  <option selected={sex === "MALE"} value={"MALE"}>M</option>
                  <option selected={sex === "FEMALE"} value={"FEMALE"}>F</option>
                </select>
              </div>
            </div>
            <div className={"row mb"}>
              <div className={"col-9"}>
                <input placeholder={"Cidade de Nascimento"} type={"text"} value={birth_place} onChange={(e) => setBirthPlace(e.target.value)}/>
              </div>
              <div className={"ml col-3"}>
                <select onChange={(e) => setUF(e.target.value)}>
                  <option value="" disabled selected hidden>UF</option>
                  {
                    UF_LIST.map((item) => <option selected={item === uf} key={item} value={item}>{item}</option>)
                  }
                </select>
              </div>
            </div>
            <div className={"row mb"}>
              <div className={"col-12"}>
                <DatePicker dateFormat="dd/MM/yyyy hh:mm aa" placeholderText={"Data de Nascimento"} showTimeSelect selected={birth_date} onChange={(date) => setBirthDate(date)} />
              </div>
            </div>
            <div className={"row mb"}>
              <div className={"col-12"}>
                <label className={"checkbox"}><input checked={noTime} onChange={(e) => setNoTime(e.target.checked)} type={"checkbox"}/>Não sei a hora de nascimento</label>
              </div>
            </div>
            <div className={"row mb"}>
              <div className={"col-12"}>
                <button type={"button"} onClick={generateMap}>Testar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={"landing-right-column"}>
        {map && <div className={"landing-planets-info-container"}>
          <img src={sex === "MALE" ? MaleImage : FemaleImage} alt={"User image"} />
          <div className={"colored-line-container"}>
            <ColoredLine />
          </div>
          <div className={"landing-user-info"}>
            <p className={"landing-user-name"}>
              {name} - {luxonBirthDate.toFormat("dd/MM/yyyy")}
            </p>
            <p className={"landing-user-born"}>
              Nascido(a) em {birth_place}, {uf} - Brasil às {luxonBirthDate.toFormat("HH:mm")}
            </p>
          </div>
          <div className={"planets"}>
            {
              map.astral_map.map((item) => <div className={"grid-row planet-row"}>
                <div className={'row'}>
                  <img src={getPlanetIcon(item[0])} alt={'Sun'} />
                  {item[0]}
                </div>
                <div className={'row'}>
                  <img src={getSignIcon(item[1])} alt={'Aries'} />
                  {item[1]}
                </div>
                <div className={'row'}>
                  {item[2]}
                </div>
              </div>)
            }
          </div>
          <div className={"mandala-container"}>
            <img src={map.mandala} alt={'mandala'} />
          </div>
        </div>}
      </div>
    </div>
    </div>
  </>
}

export default LandingPage