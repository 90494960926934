import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

//COMPONENTS
import { Footer } from '../../components';
import Button from '../../components/Button';

import Logo from '../../assets/logo-2.png';
//STYLE
import {
  MainContainer,
  GlobalStyle,
  TermsText,
  ButtonWrapper,
  ButtonContainer,
  LogoWrapper,
} from './styles';

export default function Privacy(props) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <MainContainer>
      <GlobalStyle />
      <TermsText>
        <LogoWrapper src={Logo} /> 
        <b>Termo de Privacidade Synas</b>
        <br />
        <br />
        O presente instrumento, Termo de Privacidade, é criado pela SYNAS SERVIÇOS
DE INFORMAÇÃO NA INTERNET LTDA, CNPJ/MF sob o nº 31.495.143/0001-45, em
comprometimento com a privacidade e segurança dos dados coletados de seus usuários.
Aqui será tratado com transparência as políticas de privacidade do aplicativo, que estão
alinhadas com as disposições da Lei Geral de Proteção de Dados.
        <br />
        <br />
        <b>1. Definições</b>
        <br />
        <br />
        Toda vez que houver menção aos termos “Sociedade”, “nós” ou “nossos” estaremos nos
referindo à Synas Serviços de Informação na Internet Ltda.; e toda vez que houver menção
aos termos “Usuário”, “você”, “seu” ou “sua”, estaremos nos referindo à pessoa física ou
jurídica que pretenda utilizar o aplicativo Synas. Ademais, para facilitar seu entendimento e
evitar repetições, os termos constantes neste documento, sempre que usados com a
primeira letra em maiúsculo, identificarão um termo ou expressão definida, e serão
aplicáveis para as eventuais atualizações que este documento possa vir a sofrer, exceto se
expressamente indicado o contrário.
        <br />
        <br />
        “Informações”: os dados, os documentos e os materiais que lhe sejam pertinentes;
        <br />
        “Informações Confidenciais”: todas e quaisquer informações reveladas direta ou
indiretamente, pela Sociedade, tais como características de produtos/sistemas (pré-
existentes, novos e em desenvolvimento), informações envolvendo direito e propriedade
industrial ou direito autoral, informações sobre ferramentas
de software, designs de hardware, algoritmos, design de interface de usuário,
documentação em qualquer formato, técnicas e métodos, fórmulas, demonstrações,
apresentações, relatórios, listas e preços. As Informações Confidenciais incluem todo e
qualquer conteúdo compartilhado com o Cliente, em virtude das atividades exercidas pela
Sociedade, e todas as informações fornecidas (seja de forma escrita ou oral, direta ou
indiretamente) por esta, seus representantes, conselheiros, diretores e empregados, sejam
antes ou depois da data de anuência com estes Termos e Condições de Uso e marcadas,
ou indicadas, como “Confidencial” ou similar, ou cujo destinatário entenda, de forma
razoável, serem Informações Confidenciais devido à sua natureza;
        <br />
        “Pessoas Autorizadas”: representantes e procuradores do Usuário; e
        <br />
        “Sigilo”: proteção contra o tratamento não autorizado de Informações Confidenciais.
As Informações Confidenciais reveladas deverão ser guardadas em segredo, não devendo
ser reveladas a terceiros ou utilizadas para fins diversos daqueles definidos neste
instrumento, sem a devida autorização da Sociedade.
        <br/>
        As Informações Confidenciais poderão estar contidas ou serem transmitidas por quaisquer
meios, incluindo, entre outros, as formas escrita, gráfica, verbal, mecânica, eletrônica,
digital, magnética ou criptográfica.
        <br/>
        <br />
        <b>2. Informações Coletadas</b>
        <br />
        <br />
        - Nome
        <br />
        - E-mail
        <br />
        - Data, local e horário de nascimento
        <br />
        - Informações Financeiras, em caso de utilização Premium do aplicativo
        <br />
        - Dados de uso do app
        <br />
        <br />
        <b>3. Finalidade</b>
        <br />
        <br />
        A coleta de dados pelo aplicativo tem por objetivo a viabilização da funcionalidade
da prestação de serviços da Sociedade, além de, progressivamente, melhorar a experiência
do usuário com a plataforma pelo objetivo da criação de ambiente personalizado a cada
perfil.
        <br />
        <br />
        <b>4. Tempo de Armazenamento de Dados</b>
        <br />
        <br />
        Os dados dos usuários serão armazenados enquanto perdurar a utilização do
usuário para o estrito cumprimento da finalidade descrita. Caso a conta seja desativada pelo
usuário, os dados permanecerão armazenados para consulta interna da Sociedade pelo
prazo de 90 (noventa) dias.
        <br />
        <br />
        <b>5. Medidas de Segurança</b>
        <br />
        <br />
        A Sociedade tomará todas as medidas possíveis para manter a confidencialidade e
a segurança necessárias, valendo-se dos melhores mecanismos disponíveis na
particularidade de cada necessidade.
        <br />
        Dentre os mecanismos de segurança, vale ressaltar o armazenamento de toda
informação ou dado pessoal prestado pelo Usuário à Sociedade para utilização do Synas
em banco de dados próprio, através de servidores ou meios magnéticos de alta segurança.
        <br />
        Ao se utilizar de qualquer das informações coletadas, não será identificada a
titularidade dos dados, prezado-se em todas as ocasiões pelo anonimato do usuário.
        <br />
        É importante destacar que o meio virtual está sempre sujeito a risco e, portanto, a
Sociedade não garante, de forma alguma, que tais medidas de segurança sejam isentas de
erros ou que não estejam sujeitas à interferência de terceiros (hackers, entre outros). Por
isso, apesar dos melhores esforços da Sociedade, qualquer medida de segurança pode
falhar e qualquer dado do Usuário fornecido ou coletado pela Sociedade pode se tornar
público, não respondendo a Sociedade por prejuízos que possam ser derivados dessas
violações.
        <br />
        <br />
        <b>6. Compartilhamento de Dados</b>
        <br />
        <br />
        Os dados dos usuários são apenas compartilhados de forma limitada com outros
usuários, por conta da natureza do aplicativo, que promove o &quot;encontro do par ideal com
ajuda dos astros”, sendo limitadas pelos próprios usuários as informações a serem
compartilhadas. Além dessa circunstância, apenas a determinação judicial levaria ao
compartilhamento de dados com os devidos cuidados de proteção.
        <br />
        <br />
        <b>7. Responsabilidade do Usuário</b>
        <br />
        <br />
        O usuário poderá acessar sua conta por login e senha individuais, comprometendo-
se a não informar esses dados a terceiros e responsabilizando-se integralmente pelo uso
que deles seja feito.
        <br />
        A responsabilidade pela utilização do login e senha é de inteira responsabilidade do
Usuário, bem como a atualização de seus dados cadastrais.
        <br />
        O Usuário deve notificar à Sociedade imediatamente, por meio da seção de contato
disponibilizada no aplicativo, sobre qualquer uso ou acesso não autorizado de sua conta.
        <br />
        A concordância do Usuário ao presente instrumento manifesta sua anuência
expressa para que a Sociedade utilize as informações por ele inseridas no aplicativo
exclusivamente para os fins aqui descritos.
        <br />
        O Usuário reconhece e está ciente de que o Synas, assim como qualquer outra
plataforma de tecnologia, está suscetível a falhas, erros, problemas de acesso e similares,
que fogem do controle da Sociedade.
        <br />
        Ao aceitar este Termo, o usuário entende e assume expressamente o risco de que a
Sociedade não seja responsável por eventuais vazamentos de dados, ocasionados por
falhas e erros, sendo dever da Sociedade informar imediatamente o Usuário sobre o
vazamento, para que sejam tomadas as medidas necessárias de contenção de danos.
        <br />
        <br />
        <b>8. Exceção à obrigatoriedade de sigilo</b>
        <br />
        <br />
        O dever de sigilo compete à Sociedade e ao Usuário. Não estão sujeitas ao sigilo as
informações que:
        <br />
        <br />

        <b>   a) Em relação às informações do usuário:</b>
        <br />
        <br />
        - Forem de domínio público anteriormente à sua divulgação à Sociedade, por fontes
legítimas diversas do Usuário;
        <br />
        - Cuja revelação para terceiros (que não as Pessoas Autorizadas) seja prévia e
expressamente autorizada por escrito pelo Usuário;
        <br />
        - Tenha sido comprovadamente obtida, de forma legal e legítima, por outra fonte que não
tenha, direta ou indiretamente, obrigação de sigilo com o Usuário;
        <br />
        - Ser requisitada através de ordem judicial por uma autoridade governamental competente.
        <br />
        <br />
        <b>   b) Em relação às informações da Sociedade</b>
        <br />
        <br />
        O Usuário, pelo presente, reconhece que as Informações Confidenciais são de propriedade
exclusiva da Sociedade, constituindo segredo comercial desta, e concorda que as
Informações Confidenciais serão utilizadas somente no processo de acesso ao Synas,
sendo expressamente vedada ao Usuário sua utilização para qualquer outro fim.
        <br />
        Em caso de dúvida acerca da confidencialidade de determinada informação, o Usuário
deverá tratar a mesma sob sigilo até que venha a ser autorizada a tratá-la diferentemente
pela Sociedade. De forma alguma se interpretará o silêncio da Sociedade como liberação
do compromisso de manter o sigilo da informação.
        <br />
        As Informações Confidenciais fornecidas só deverão ser divulgadas às Pessoas
Autorizadas, na estrita medida em que se fizer necessária tal divulgação, devendo o
Usuário informar as mesmas sobre a natureza confidencial das Informações, bem como a
fazer com que essas pessoas tratem referidas Informações como confidenciais, na forma
disposta nestes Termos e Condições de Uso.
        <br />
        O Usuário, sem prejuízo das demais obrigações previstas no presente termo, compromete-
se a comunicar previamente à Sociedade, na hipótese de solicitação de divulgação das
Informações Confidenciais em razão do cumprimento de lei, determinação judicial ou de
órgão competente fiscalizador das atividades envolvidas por qualquer das Partes, sendo,
em qualquer dos casos, divulgadas somente nos limites estritamente requeridos para a sua
divulgação.
        <br />
        Só serão legítimos motivos de exceção à obrigatoriedade de sigilo, a ocorrência de
divulgação, nas hipóteses em que a Informação que:
        <br />
        <br />
        *for de domínio público anteriormente à sua divulgação ao Usuário, por fontes legítimas
diversas da Sociedade;
        <br />
        *já for de conhecimento do Usuário quando seja divulgada ao mesmo, por fontes legítimas
diversas da Sociedade;
        <br />
        *se tornar publicamente disponível para terceiros após a divulgação para o Usuário, sem
que tal fato tenha ocorrido por culpa ou dolo;
        <br />
        *tenha sido comprovadamente obtida, de forma legal e legítima, por outra fonte que não
tenha, direta ou indiretamente, obrigação de sigilo com o Usuário;
        <br />
        * a revelação para terceiros (que não as Pessoas Autorizadas) tenha sido prévia e
expressamente autorizada por escrito pelo Usuário; e
        <br />
        * for requisitada através de ordem judicial por uma autoridade governamental competente,
sendo, neste caso, necessário que o Usuário notifique com antecedência a Sociedade e
seus representantes do pedido, além de utilizar esforços comercialmente razoáveis para
limitar a extensão de tal abertura.
        <br />
        <br />
        <b>9. Alterações no termo de Privacidade</b>
        <br />
        <br />
        A Sociedade poderá modificar este documento, a qualquer momento, para refletir
eventuais alterações necessárias. eventuais versões atualizadas serão publicadas no
mesmo site indicado acima, que deverá ser consultado regularmente.
        
        <br />
        <br />
        <b>10. Vigência</b>
        <br />
        <br />
        Este Termo terá prazo indeterminado, tendo início a partir do aceite do Usuário.
        <br />
        <br />
        <b>11. Dúvidas</b>
        <br />
        <br />
        Caso você tenha qualquer dúvida sobre este instrumento, recomendamos que entre
em contato com a Sociedade pela seção de contato de seu aplicativo.
        <br />
        <br />
      </TermsText>

      <Link to="/">
        <ButtonWrapper>
          <ButtonContainer>
            <Button>Entendi</Button>
          </ButtonContainer>
        </ButtonWrapper>
      </Link>

      <Footer />
    </MainContainer>
  );
}
